import React from "react";
import _ from "lodash";
import moment from "moment-strftime";

import { Layout } from "../components/index";
import { toStyleObj, safePrefix, htmlToReact } from "../utils";

export default class Post extends React.Component {
  render() {
    return (
      <Layout {...this.props}>
        <article className="post post-full">
          <header className="post-header bg-gradient outer">
            {_.get(this.props, "pageContext.frontmatter.content_img_path") && (
              <div
                className="bg-img"
                style={toStyleObj(
                  "background-image: url('" +
                    _.get(
                      this.props,
                      "pageContext.frontmatter.content_img_path"
                    ) +
                    "')"
                )}
              />
            )}
            <div className="inner-small">
              <div className="post-meta">
                <time
                  className="published"
                  dateTime={moment(
                    _.get(this.props, "pageContext.frontmatter.date")
                  ).strftime("%Y-%m-%d %H:%M")}
                >
                  {moment(
                    _.get(this.props, "pageContext.frontmatter.date")
                  ).strftime("%B %d, %Y")}
                </time>
              </div>
              <h1 className="post-title">
                {_.get(this.props, "pageContext.frontmatter.title")}
              </h1>
              {_.get(this.props, "pageContext.frontmatter.subtitle") && (
                <div className="post-subtitle">
                  {htmlToReact(
                    _.get(this.props, "pageContext.frontmatter.subtitle")
                  )}
                </div>
              )}
            </div>
          </header>
          <div className="outer">
            <div className="inner-medium">
              <div className="post-content">
                {htmlToReact(_.get(this.props, "pageContext.html"))}
              </div>
            </div>
          </div>
        </article>
      </Layout>
    );
  }
}
